<!-- SIDEBAR -->
<app-sidebar></app-sidebar>
<!-- END SIDEBAR -->
<div class="container-fluid">
  <div class="row">
    <!-- TABLE CONTENT -->
    <div class="col offset-2 table-responsive mt-3" id="main">
      <!-- <div class="col offset-2 table-responsive" id="main"> -->
      <!-- <div *ngIf="loading" class="custom-spinner"></div> -->

      <div class="edit-location-form mt-4 form-group p-3">
        <div class="row">
          <!-- <h2 class="mb-4">Detalles y Edición de Ubicación</h2> -->
          <h2 class="mb-4"> <i class="fa-solid fa-location-dot"> <strong> Detalles </strong> </i></h2>
        </div>

        <div class="row">

          <div class="col-md-3 mb-3">
            <label for="codigoTienda" class="form-label">Código de Tienda:</label>
            <input type="text" [(ngModel)]="locationInfo.codigo_tienda " name="codigoTienda" class="form-control" readonly
              [value]="locationInfo.codigoTienda">
          </div>

          <div class="col-md-9 mb-3">
            <label for="nombre" class="form-label">Nombre:</label>
            <input type="text" [(ngModel)]="locationInfo.nombre" name="nombre" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="direccion" class="form-label">Dirección:</label>
            <input type="text" [(ngModel)]="locationInfo.direccion" name="direccion" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-2 mb-3">
            <label for="direccion" class="form-label">Código postal:</label>
            <input type="text" [(ngModel)]="locationInfo.codigo_postal" name="provincia" class="form-control">
          </div>

          <div class="col-md-5 mb-3">
            <label for="direccion" class="form-label">Localidad:</label>
            <input type="text" [(ngModel)]="locationInfo.localidad" name="localidad" class="form-control">
          </div>

          <div class="col-md-5 mb-3">
            <label for="direccion" class="form-label">Provincia:</label>
            <input type="text" [(ngModel)]="locationInfo.provincia" name="provincia" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="direccion" class="form-label">Teléfono:</label>
            <input type="text" [(ngModel)]="locationInfo.telefono" name="provincia" class="form-control">
          </div>

          <div class="col-md-8 mb-3">
            <label for="direccion" class="form-label">Web:</label>
            <input type="text" [(ngModel)]="locationInfo.web" name="provincia" class="form-control">
          </div>
        </div>

        <hr class="col-md-6 mt-2 hr-separate">

        <!-- horarios -->
        <h2> <i class="fa-regular fa-clock"> Horarios</i></h2>
        <div id="horarios-container">
          <div *ngFor="let timeKey of getRegularHoursByTimeKeys(); let timeIndex = index">
            <div class="schedule">
              <details class="detail" (toggle)="updateSelectedDaysSummary(timeKey)">
                <summary class="summary">
                  <span>{{selectedDaysSummary[timeKey] || 'Selecciona días'}}</span>
                </summary>
                <div class="details">
                  <!-- Itera sobre allDaysOfWeek en lugar de getDaysForTime(timeKey) -->
                  <div *ngFor="let dayObj of getDaysForTime(timeKey)">
                    <input type="checkbox" [checked]="dayObj.isSelected" [value]="dayObj.name"
                      [id]="'checkbox_' + dayObj.name + '_' + timeIndex"
                      (change)="onDaySelectionChange(dayObj.name, $event, timeKey)">
                    <label [for]="'checkbox_' + dayObj.name + '_' + timeIndex">{{ dayObj.name }}</label><br>
                  </div>
                </div>
              </details>
              <!-- Horario de Apertura -->
              <select class="select" (change)="onTimeChange(timeKey, $event, 'open')">
                <option *ngFor="let hour of getHourlyIntervals()" [value]="hour"
                  [selected]="hour === timeKey.split('-')[0]">{{ hour }}</option>
                <option [value]="'Cerrado'">Cerrado</option>
              </select>

              <!-- Horario de Cierre -->
              <select class="select" (change)="onTimeChange(timeKey, $event, 'close')">
                <option *ngFor="let hour of getHourlyIntervals()" [value]="hour"
                  [selected]="hour === timeKey.split('-')[1]">{{ hour }}</option>
                <option [value]="'Cerrado'">Cerrado</option>
              </select>


              <button class="btn-x" (click)="removeTimeKey(timeKey)">X</button>
              <!-- <button class="btn-x">X</button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="schedules">
        <div id="schedules-container">
          <!-- Aquí se agregarán los horarios -->
        </div>
        <!-- <button onclick="agregarHorario()">+ Añadir nuevo horario</button> -->
        <button class="btn btn-add-schedule" (click)="addSchedule()">+ Añadir nuevo horario</button>
      </div>
      
      <div class="mt-4">
        <button (click)="onSave()" class="btn btn-dark">Guardar Cambios</button>
        <button (click)="btnBack()" class="btn btn-outline-dark back">Atrás</button>
      </div>
    </div>

  </div>