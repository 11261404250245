import { Injectable } from '@angular/core';
import { historico } from '../components/historico/historico.model';
/* eslint-disable */

@Injectable({
  providedIn: 'root'
})
export class DrawStatsService {
  s: any
  star: any
  constructor() { }

  public getClassByNumber(number: number) {
    if (number > 0) {
      return "positive";
    } else {
      return "negative";
    }
  }
  public cleanTildes(text: string) {
    let s = text
    let r = s.toUpperCase();
    var map = {
      // '-' : ' ',
      // '-' : '_',
      'a': 'á|à|ã|â|À|Á|Ã|Â',
      'e': 'é|è|ê|É|È|Ê',
      'i': 'í|ì|î|Í|Ì|Î',
      'O': 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
      'u': 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      'c': 'ç|Ç',
      'n': 'ñ|Ñ'
    };

    for (var pattern in map) {
      //  r = r.replace(new RegExp(map[pattern], 'g'), pattern);
    };

    return r;
  };

  public changeTypology(text: string) {
    let name = text;
    // if (text=="PRODUCTOS") name = "PRODUCTO";
    return name;
  }

  public cleanNameByTypology(text: string) {
    var nameTypology = text;
    nameTypology = this.changeTypology(nameTypology)
    nameTypology = this.cleanTildes(nameTypology);

    return nameTypology;
  }

  public getIcon(name: string, number: any) {
    let number1 = parseInt(number)
    name = this.cleanNameByTypology(name)
    if (number1 < 3) {
      return `../assets/img/typology/${name}_negative.png`;
    } else if (number1 >= 3 && number1 < 4) {
      return `../assets/img/typology/${name}_neutral.png`;
    } else {
      return `../assets/img/typology/${name}_positive.png`;
    }
  }

  public getStar(number: number, i: number): any {

    if (i <= number) {
      return `../assets/img/star-small-grey.png`
    } else {
      return `../assets/img/star-small-unfill-grey.png`
    }

  }

  public getStarPanel(number: number, i: number): any {

    if (i <= number) {
      return `../assets/img/_star-small.png`
    } else {
      return `../assets/img/star-small-unfill.png`
    }

  }

  public getColor(number: number) {
    if (number < 3) {
      return "table-color-bad";
    } else if (number >= 3 && number < 4) {
      return "table-color-neutral";
    } else {
      return "table-color-good";
    }
  }
  public getColorAverage(number: number) {
    if (number < 3) {
      return "average-color-bad";
    } else if (number >= 3 && number < 4) {
      return "average-color-neutral";
    } else {
      return "average-color-good";
    }
  }

  public parseDecimales(score: number) {
    let number = "";
    if (score) {
      number = score.toString()
    }
    return number.replace('.', ',')
  }

  public getClassByRouter(url: string) {
    if (url === "/panel") {
      return "hidden";
    } else {
      return "visible";
    }
  }

  public getColorHistory(name: string, data: number, index: number, historico: any) {
    if (historico[index + 1] !== undefined) {
      if (eval("historico[index]." + name) === eval("historico[index+1]." + name)) {
        return "neutral"
      } else if (eval("historico[index]." + name) > eval("historico[index+1]." + name)) {
        return "high"
      } else {
        return "low"
      }
    } else {
      return "neutral";
    }
  }

  public getPercentage(name: string, index: number, historico: any) {
    if (historico[index + 1] !== undefined) {
      let valActual = eval("historico[index]." + name);
      let valAnterior = eval("historico[index+1]." + name);
      let percentage = (valActual - valAnterior) / valAnterior;
      let percentageb = percentage * 100
      let percentageFinal = percentageb.toFixed();
      return percentageFinal;
    } else {
      return "0";
    }
  }
  public getLanguageEn(lang: string) {
    if (lang === 'en') {
      return 'hidden';
    } else {
      return 'visible';
    }
  }
  public getLanguageEs(lang: string) {
    if (lang === 'es') {
      return 'hidden';
    } else {
      return 'visible';
    }
  }
  // fijar decimales del rating a 2
  public fixedNumber(ratingData: string) {
    let numberRating = parseFloat(ratingData);
    return numberRating.toFixed(2);
  }
  // comprobar si existe la palabra reply en el array que le mandemos por parámetro
  public existReply(object) {
    // Verifica si la palabra clave "reply" existe en alguna parte del objeto
    if (object.hasOwnProperty('reply')) {
      return true;
    }
    // Itera sobre cada propiedad del objeto
    for (const key in object) {
      // Verifica si la propiedad es un objeto
      if (typeof object[key] === 'object' && object[key] !== null) {
        if (this.existReply(object[key])) {
          return true;
        }
      }
    }
    // Si no se encuentra la palabra clave "reply", retorna false
    return false;
  }
  // public existReplay(object){
  //   let verification = Object.keys(object).some(key => key === 'reply');
  //   if(verification == true){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // public existReplay(array){
  //   return array.includes("reply");
  // }

  public showDate(dateData: string) {
    let date
  }

  //funcion para añadir spiner
  public addSpinner() {
    const cortina: any = document.getElementById('cortina');
    const spinner: any = document.getElementById('loaderSpinner');
    cortina.classList.add('cortina');
    spinner.classList.remove('hidden');
  }

  //para quitar el spinner
  public removeSpinner() {
    const spinner: any = document.getElementById('loaderSpinner');
    const cortina: any = document.getElementById('cortina');
    spinner.classList.add('hidden');
    cortina.classList.remove('cortina');
  }

  //para renombrar los dias de la semana en español
  // Función para convertir el nombre del día de la semana de inglés a español
  public translateDayOfWeek(day: string): string {
    switch (day.toUpperCase()) {
      case 'MONDAY':
        return 'Lunes';
      case 'TUESDAY':
        return 'Martes';
      case 'WEDNESDAY':
        return 'Miércoles';
      case 'THURSDAY':
        return 'Jueves';
      case 'FRIDAY':
        return 'Viernes';
      case 'SATURDAY':
        return 'Sábado';
      case 'SUNDAY':
        return 'Domingo';
      default:
        return day; // Devuelve el mismo nombre si no coincide con ninguno de los días de la semana en inglés
    }
  }

  //función para traducir los dias de la semana de vuelta al inglés para mandarlos de vuelta a la api
  public translateDayOfWeekBack(day: string): string {
    const dayTranslations = {
      'Lunes': 'MONDAY',
      'Martes': 'TUESDAY',
      'Miércoles': 'WEDNESDAY',
      'Jueves': 'THURSDAY',
      'Viernes': 'FRIDAY',
      'Sábado': 'SATURDAY',
      'Domingo': 'SUNDAY'
    };

    return dayTranslations[day] || day;
  }
}

