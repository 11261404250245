import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): boolean {
    const token = localStorage.getItem('access_token');
    if (!token) {
      // Si no hay token, redirige al login
      this.router.navigate(['']);
      return false; // Bloquea el acceso
    }
    return true; // Permite el acceso
  }
}