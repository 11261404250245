import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  password: string;
  showValidateMessage: boolean;
  token: string;
  showErrorMessage: boolean;
  constructor(private route: ActivatedRoute,public router: Router,private authService: AuthService) {
    this.password= '';
    this.showValidateMessage = false;
    this.showErrorMessage = false;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params.token;
    });
  }
  isPasswordSecure(): boolean {
 //se requieren al menos 8 caracteres, incluyendo letras mayúsculas, minúsculas, números y caracteres especiales
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>.]{8,}$/;
    return passwordRegex.test(this.password);
  }

  resetPassword(): void {
      this.authService.postPasswordForgot(this.password,this.token).then(res => {
        this.showValidateMessage = true;
        this.showErrorMessage = false;
        setTimeout(() => {
          this.router.navigate(['']);
        }, 5000);
      }).catch(err => {this.showErrorMessage = true; this.showValidateMessage = false;});
  }

}
