<body>
<div class="container">        
<div class="row">
<div class="forgot-password-container">
    <h2 class="title">Restablecer Contraseña</h2>
    <form (ngSubmit)="resetPassword()" class="password-form">
      <div class="form-group">
        <label class="title" for="password">Nueva Contraseña:</label>
        <input type="password" id="password" name="password" [(ngModel)]="password" required>
      </div>
      <div *ngIf="password && !isPasswordSecure()" class="password-validation-msg">
        La contraseña debe contener al menos 8 caracteres, incluyendo letras mayúsculas, minúsculas, números y caracteres especiales.
      </div>
      <button type="submit" [disabled]="!password || !isPasswordSecure()" class="btn-reset-password">Restablecer Contraseña</button>
    </form>
    <div *ngIf="showValidateMessage">
        <p class="validate-ok">Contraseña reiniciada</p>
    </div>
    <div *ngIf="showErrorMessage">
        <p class="error-login">No se pudo cambiar la contraseña, por favor contacte con un administrador</p>
    </div>
  </div>
</div>
</div>
</body>