import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationsService } from 'src/app/service/locations.service';
import { LocationsList } from './locations.model';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
import { Router } from '@angular/router';
/* eslint-disable */

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})

export class LocationsComponent implements OnInit {
  // Declaración de variable para almacenar los datos y los que vamos a usar en dentro de componente
  LocationsInfo: LocationsList;
  drawStats: DrawStatsService;
  dtOptions: DataTables.Settings = {};
  // cat: any;
  // locationsDate: number;  //si fuera una fecha
  // verifyLocation: boolean; //para verificar, por ejemplo, si existe
  currentPage = 0;
  itemsPerPage = 14;
  loading: boolean = true;

  selectedLocation: any; // La ubicación seleccionada para editar


  constructor(private locationsService: LocationsService,public router: Router) { 
    this.drawStats = new DrawStatsService();
    this.LocationsInfo = this.getLocationDefault();
    this.locationsLoad();    
  
  }

  ngOnInit() {
    // this.dtOptions = {
    //   pagingType: 'simple_numbers',
    //   displayStart:300,
    //   // order:(['asc',3]),
    //   language:{
    //     "emptyTable":     "No existen datos actualmente",
    //     "infoPostFix":    "",
    //     "loadingRecords": "Cargando...",
    //     "processing":     "Procesando...",
    //     "search":         "Buscar por : ",
    //     "zeroRecords":    "No se encontraron resultados",
    //     "paginate": {
    //         "first":    "<<",
    //         "last":     ">>",
    //         "next":     ">",
    //         "previous": "<"
    //     },
    // }
    // };
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      serverSide: false, // Esto puede necesitar ser true si manejas grandes conjuntos de datos
      language: {
        // info: 'Mostrando la página _PAGE_ de _PAGES_',
        // infoEmpty: 'No records available',
        // infoFiltered: '(filtrado de _MAX_ registros totales)',
        // lengthMenu: 'Mostrar _MENU_ registros por página',
        // zeroRecords: 'Nada encontrado - disculpa',
        // 'search': 'Buscar:',
        url: '//cdn.datatables.net/plug-ins/2.0.3/i18n/es-ES.json',
    }
    };
    
  }
  

  //cargar la lista de localidades
  public locationsLoad() {
    const brandId = localStorage.getItem('brandId');
    // const id = localStorage.getItem('companyId'); //cogemos el id del localStorage
    this.locationsService.getInfoLocations(brandId)
      .subscribe(response => {
        this.fillLocationData(response);
        this.loading = false; // Marcar como no cargando después de obtener los comentarios
    },
    (error) => {
      console.error('Error al obtener las ubicaciones:', error);
      this.loading = false; // Marcar como no cargando en caso de error
    });
  }

//función para rellenar la petición que hagamos con los datos que queremos
  public fillLocationData(dataPetiton: any) {
    // console.log(dataPetiton, 'esto es la peticion de datos');
    const locationData = [];
    
    for (let i in dataPetiton) {
      const newLocationsData = {
          codigoTienda: dataPetiton[i].codigo_tienda,
          nombre: dataPetiton[i].nombre,
          direccion: dataPetiton[i].direccion,
          localidad: dataPetiton[i].localidad,
          provincia: dataPetiton[i].provincia,
          totalReviews: dataPetiton[i].total_reviews,
          rating: this.drawStats.fixedNumber(dataPetiton[i].rating),
          id: dataPetiton[i]._id,
        };
        
        locationData.push(newLocationsData);
    }
    
    this.LocationsInfo.locations = locationData;
    $(document).ready(()=>{
      $("#locations_table").DataTable(this.dtOptions);
    })
  }

  //funcion para editar
  public onSaveLocation(updatedLocation: any) {
    // Actualizar la ubicación en la lista
    const index = this.LocationsInfo.locations.findIndex(loc => loc.codigoTienda === updatedLocation.codigoTienda);
    if (index !== -1) {
      this.LocationsInfo.locations[index] = updatedLocation;
    }
  }

  //funcion para seleccionar la ubicación 
  public onLocationClick(location: any) {
    this.selectedLocation = location;
  }


  //para poder reutulizar esta funcion en otros componentes la ponemos dentro de drawStatsService 
  // public fixedNumber (ratingData: string) {
  //   let numberRating = parseFloat(ratingData);
  //     return numberRating.toFixed(2);
  // }
  
  panel() {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
      this.router.navigate(['/panel']);
    }
  };

  public getLocationDefault(){
    return {
      locations: [
        {
          codigoTienda: 'sin datos',
          nombre: 'sin datos',
          direccion: 'sin datos',
          localidad: 'sin datos',
          provincia: 'sin datos',
          totalReviews: 0,
          rating: 0,
          id: '0',
        },

        {
          codigoTienda: 'sin datos',
          nombre: 'sin datos',
          direccion: 'sin datos',
          localidad: 'sin datos',
          provincia: 'sin datos',
          totalReviews: 0,
          rating: 0,
          id: '0',

        }
      ]
    }
  }
}


