<!-- SIDEBAR -->
<app-sidebar></app-sidebar>
<!-- END SIDEBAR -->
<div class="container-fluid">
    <router-outlet></router-outlet>
    <div class="row">
        

        <!-- Content start -->
        <!-- -----------BARRA DE FILTROS----------- -->
        <div class="container my-4 mt-0 offset-2 fixed-top">
            <div class="bg-white pt-4 pb-0 mb-0 show-comments-number">
                <h3 class="bg-white mb-0"><strong>Total de Comentarios: </strong>{{ totalComments }}</h3>
                <h3 class="bg-white pb-0 mb-0" *ngIf="totalReviews !== null && totalReviews !== undefined"><strong>Total comentarios mostrados: </strong> {{ totalReviews }}</h3>
                <!-- <h3>Total de comentarios filtrados: {{ totalComments }}</h3> -->
                <h3 class="bg-white mb-0" *ngIf="totalFilteredComments > 0"><strong>Total de Comentarios Filtrados: </strong>{{ totalFilteredComments }}</h3>
            </div>

            <div class=" bg-white p-2 pt-0 ps-0 rounded-lg shadow-sm d-flex flex-wrap align-items-star">
                <!-- FILTRO RAITING -->

                <div class="dropdown">
                    <button (click)="toggleDropdown()" class="d-flex align-items-center justify-content-between">
                        <!-- Modificar aquí para mostrar "Todos" o los ratings seleccionados -->
                        <span>
                            {{ isAllSelected ? 'Todos' : (selectedRatings.length > 0 ? selectedRatings.join(' &#9733;, ') + ' &#9733;' : 'Rating') }}
                        </span>
                        
                        <i class="fa-solid fa-chevron-down"></i>
                    </button>

                    <div id="myDropdown" class="dropdown-content" [class.show]="dropdownVisible">
                        <!-- Checkbox para seleccionar/deseleccionar todos -->
                        <a>
                            <input type="checkbox" id="rating-all" (change)="toggleAllRatings($event.target)">
                            <label for="rating-all">Todos </label>
                        </a>
                        <!-- Checkboxes para cada rating específico -->
                        <a *ngFor="let rating of [5,4,3,2,1]">
                            <input type="checkbox" [id]="'rating-' + rating"
                                (change)="toggleRatingFilter(rating.toString(), $event)">
                            <label [for]="'rating-' + rating">{{rating}} &#9733;</label>
                        </a>
                    </div>
                </div>


                <!-- Filtro de Estado de Respuesta -->
                <!-- Selector para el Filtro de Comentarios -->
                <select class="form-select comments ms-sm-0 ms-md-3" aria-label="Filtro de Comentarios" placeholder="Comentarios"
                    (change)="loadCommentsBasedOnFilter($event)">
                    <!-- <option selected>Comentarios</option> -->
                    <option value="all">Mostrar todos</option>
                    <option value="not-answered">Sin responder</option>
                    <option value="answered">Respondidos</option>
                </select>


                <!-- <div *ngIf="loadingFilter" class="custom-spinner"></div> -->
                <!-- <div *ngIf="loadingFilter" class="spinner-overlay">
                    <div class="custom-spinner"></div>
                </div> -->

                <!-- Filtros de Fecha -->
                <div class="date-filters d-flex flex-sm-wrap flex-md-nowrap ">
                    <input #dateStartInput
                        [attr.type]="dateStart ? 'date' : 'text'"
                        [(ngModel)]="dateStart" 
                        (focus)="updateType('start', 'date')" 
                        (blur)="updateType('start', 'text')"
                        [placeholder]="!dateStart ? 'Fecha Inicio' : ''"
                        class="form-control date-filter ms-sm-0 ms-md-3">

                    <input #dateEndInput
                        [attr.type]="dateEnd ? 'date' : 'text'"
                        [(ngModel)]="dateEnd" 
                        (focus)="updateType('end', 'date')" 
                        (blur)="updateType('end', 'text')"
                        [placeholder]="!dateEnd ? 'Fecha Fin' : ''"
                        class="form-control date-filter ms-sm-0 ms-md-3">
                </div>

                        <!-- Aquí van los resultados del filtrado -->

                <!-- Botón para aplicar filtros -->
                <div class="btn-filter">
                    <button class="btn btn-dark btn-sm mx-md-4 mx-sm-0 mt-sm-1 mt-md-0 filters" (click)="applyFilters()">
                        APPLY FILTERS
                    </button>
                </div>
            </div>
        </div>

        <!-- ----------CONTENIDO DE LOS COMENTARIOS---------- -->
        <div class="row content mt-5 pt-5 pt-md-0 mt-md-4">
            <div class="col-9 offset-2 col-md-8 offset-3 p-0 pt-5 mt-5" infiniteScroll   ngxInfiniteScroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollUpDistance]="scrollUpDistance"
            [infiniteScrollThrottle]="throttle"  (scrolled)="loadMoreComments()">
                <br>
                <br>
                <div *ngFor="let reviewInfo of reviews.review" class="comment-card p-1 p-lg-5 my-2 card"
                [ngStyle]="{ 'background-color': getBackgroundColor(reviewInfo.rating) }">

                    <div class="comment-header" style="display: flex; align-items: center;">
                        <div class="comment-author-div" style="flex-grow: 1;">
                            <span class="comment-author bold">{{ reviewInfo.author }}</span>
                            <div class="rating" >
                                <span class="rating-stars">
                                    <span *ngFor="let star of [1, 2, 3, 4, 5]" class="star"
                                        [class.star-filled]="star <= reviewInfo.rating"
                                        [class.star-empty]="star > reviewInfo.rating">
                                        &#9733; <!--Caracter de estrella-->
                                    </span>
                                </span>
                            </div>
                            
                        </div>
                        <span class="comment-date">  {{ reviewInfo.date | date:'yyyy-MM-dd' }}  </span>                   
                    </div>

                    <span class="adress-line">
                        <!-- No necesitas iterar nuevamente sobre reviews.review aquí -->
                        <a class="adress" [href]="reviewInfo.urlReviews" target="_blank">
                            <i class="fa-solid fa-location-dot"></i> {{ reviewInfo.addressLines.length > 0 ? reviewInfo.addressLines[0] : 'Dirección no disponible' }}
                        </a>
                    </span>

                    <div class="comment-content">{{ reviewInfo.comment }}</div>
                    
                    
                    <!-- <hr class="hr-comment"> -->
                    <div class="button-container">
                        <!-- Este botón se muestra solo si la reseña ha sido respondida -->
                        <button class="btn btn-success btn-sm" *ngIf="reviewInfo.isAnswered"
                            (click)="toggleResponses(reviewInfo)">Ver respuesta</button>
                    </div>

                    <!-- Mostrar respuestas si existen -->
                    <div
                        *ngIf="reviewInfo.isAnswered && reviewInfo.responses && reviewInfo.responses.length > 0 && reviewInfo.showResponses">
                        <ul class="px-4">
                            <!-- Utilizamos *ngFor para iterar sobre las respuestas -->
                            <li *ngFor="let response of reviewInfo.responses">
                                <p class="mt-4 small">{{ response.comment }}</p>
                                <p class="comment-date"> {{ response.updateTime | date:'yyyy-MM-dd' }}
                                </p>
                            </li>
                        </ul>
                    </div>

                    <!-- Aquí agregamos la sección de respuesta -->
                    <!-- Botón para añadir respuesta, solo visible si la reseña no ha sido respondida -->
                    <div *ngIf="!reviewInfo.isAnswered">
                        <!-- Este botón se muestra solo si la reseña NO ha sido respondida -->
                        <div class="button-container">
                            <div *ngIf="!reviewInfo.isAnswered">
                                <button class="btn btn-warning btn-sm" (click)="toggleResponseForm(reviewInfo)">Añadir
                                    respuesta</button>
                            </div>
                        </div>
                    </div>


                    <!-- <div *ngIf="!reviewInfo.isAnswered" class="form mt-4"> -->
                    <div *ngIf="!reviewInfo.isAnswered && reviewInfo.showResponseForm" class="form mt-4">
                        <textarea [(ngModel)]="responseText[reviewInfo.id]" class="form-control my-3"
                            placeholder="Responder reseña" id="floatingTextarea"
                            (input)="checkTextarea(reviewInfo.id)"></textarea>

                        <button class="btn btn-success"
                            (click)="responseComment(reviewInfo, responseText[reviewInfo.id])"
                            [disabled]="isTextareaEmpty(reviewInfo.id)">Responder</button>
                    </div>

                    <!-- Mensajes de error de envío de respuesta -->
                    <div *ngIf="errorMessages[reviewInfo.id]" class="error-message">
                        {{ errorMessages[reviewInfo.id] }}
                    </div>

                    <!-- Paginación al final de la página -->
                    <!-- <div class="fixed-bottom bg-white offset-2 d-flex flex-center justify-content-center pt-4">
                        <pagination-controls (pageChange)="currentPage = $event" previousLabel="" nextLabel=""
                            [maxSize]="7"></pagination-controls>
                    </div> -->
                </div>
            </div>
