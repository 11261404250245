import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CompaniesReviews } from './companies-reviews.model';
import { CompaniesReviewsService } from 'src/app/service/companies-reviews.service';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
/* eslint-disable */
@Component({
  selector: 'app-companies-reviews',
  templateUrl: './companies-reviews.component.html',
  styleUrls: ['./companies-reviews.component.scss'],
  // imports: [CommonModule, InfiniteScrollModule],
  // standalone: true,
})


export class CompaniesReviewsComponent implements OnInit {
  reviews: CompaniesReviews;
  drawStats: DrawStatsService;
  comments: any[] = [];
  responseText: { [commentId: string]: string } = {};
  currentPage = 1;
  itemsPerPage = 10;
  scrollDistance = 0;
  scrollUpDistance = 0;
  throttle = 300;
  errorMessageText: string = '';
  errorMessages: { [commentId: string]: string } = {};
  errorTimeouts: { [commentId: string]: any } = {}; // Almacenar los identificadores de los temporizadores
  subscriptions: Subscription[] = [];
  loading: boolean = true;
  loadingOptions: boolean = false;
  loadingFilter: boolean = false;
  selectedRatings: string[] = [];
  selectedStatus: string = 'all';
  dropdownVisible: boolean = false;
  currentRating: string = '';
  displayedRatings: string = ''; // Para mostrar en el input
  formattedRatings: string = '';
  isAllSelected = false;
  loadingMore = false;
  hasMoreComments: boolean = true;
  actualPage = 1;
  totalComments = 0;
  commentsPerPage = 10;
  page = 1;
  limit = 10;
  totalReviews: number = 0;
  totalFilteredComments = 0;
  dateStart: string;
  dateEnd: string;
  // dateStart: string = '2023-01-01';
  // dateEnd: string;

  @ViewChild('dateStartInput') dateStartInput: ElementRef;
  @ViewChild('dateEndInput') dateEndInput: ElementRef;

  constructor(private reviewsService: CompaniesReviewsService, public router: Router) {
    this.reviews = this.getReviewsDefault();
    this.drawStats = new DrawStatsService();
    // this.dateEnd = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.getComments();
    this.loadInitialComments();
    window.addEventListener('scroll', this.onScroll.bind(this));
  }


  //Mmétodo para el onDestroy
  public ngOnDestroy(): void {
    // Limpiar temporizadores y desuscribirse para evitar pérdida de memoria
    Object.values(this.errorTimeouts).forEach(timerId => clearTimeout(timerId));
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    window.removeEventListener('scroll', this.onScroll.bind(this));
  }
  //función que carga los comentarios iniciales
  loadInitialComments() {
    this.actualPage = 1; // Reiniciar la página actual a 1
    const id = localStorage.getItem('brandId');
    // Añade un objeto de consulta vacío como segundo argumento
    this.reviewsService.getTotalCommentsCount(id, {}).subscribe(data => {
      this.totalComments = data.total;
    });
  }

  //función que carga mostrar el total de comentarios que existen
  loadTotalComments() {
    const id = 'yourIdHere';  // Set your actual ID here
    // Añade un objeto de consulta vacío como segundo argumento
    this.reviewsService.getTotalCommentsCount(id, {}).subscribe({
      next: (response) => {
  
        this.totalComments = response.total; // Asumiendo que 'total' es la propiedad que contiene el total de comentarios
      },
      error: (error) => {
        // console.error('Error fetching total comments:', error);
      }
    });
  }


  //función para obtener los comentarios
  public getComments() {
    const id = localStorage.getItem('brandId');
    
    this.reviewsService.getCommentsPage(id, this.page, this.limit).subscribe((comments) => {
      // this.comments = comments;
      this.fillReviewsData(comments);
      this.loading = false; // Marcar como no cargando después de obtener los comentarios
      // this.loadingMore = false;
      // this.loadMoreComments(); // Cargar más comentarios después de obtener los primeros
    },
      (error) => {
        console.error('Error al obtener comentarios:', error);
        this.loading = false; // Marcar como no cargando en caso de error
        this.loadingMore = false;
      });
  }

  //para aplicar filtros cuando le das al botón correspondiente
  public applyFilters() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      this.actualPage = 1;  // Reiniciar el contador de página a 1
      let today = new Date().toISOString().split('T')[0];  // Fecha de hoy en formato YYYY-MM-DD
      let defaultStartDate = '';  // Fecha de inicio predeterminada

      let query = {
        rating: [],
        reply_exists: undefined,
        dateStart: this.dateStart || defaultStartDate,
        dateEnd: this.dateEnd || today
      }

      if (this.selectedRatings.length > 0) {
        query.rating = this.selectedRatings;
      }
      if (this.selectedStatus === 'not-answered') {
        query.reply_exists = false;
      }
      if (this.selectedStatus === 'answered') {
        query.reply_exists = true;
      }

      this.loadingOptions = true; // Mostrar spinner al hacer clic en "Mostrar Todas las Reseñas"
      const id = localStorage.getItem('brandId');
      // Aquí actualizamos el total de comentarios filtrados
      this.reviewsService.getTotalCommentsCount(id, query).subscribe(data => {
        this.totalFilteredComments = data.total;
      });
      // Incluyendo las fechas en la consulta
      this.reviewsService.getReviews(id, query, this.actualPage).subscribe((comments) => {
        this.actualPage++;
        this.comments = comments;
        this.fillReviewsData(comments);
        this.loadingOptions = false; // Ocultar spinner después de obtener los comentarios
      },
        (error) => {
          console.error('Error al obtener comentarios:', error);
          this.loadingOptions = false; // Ocultar spinner en caso de error
        }
      )
    }, 500);
  }

  //función que carga más comentarios comentarios después de los iniciales
  loadMoreComments() {
    if (!this.loadingMore && this.comments.length < this.totalComments) {
      this.loadingMore = true;
      const id = localStorage.getItem('brandId');

      // Preparamos el objeto de consulta con los filtros aplicables
      let query = {
        rating: this.selectedRatings.length > 0 ? this.selectedRatings.join(',') : undefined,
        reply_exists: this.selectedStatus === 'not-answered' ? false : this.selectedStatus === 'answered' ? true : undefined,
        dateStart: this.dateStart, // Asegúrate de incluir el filtro de fecha de inicio
        dateEnd: this.dateEnd // Asegúrate de incluir el filtro de fecha de fin
      };

      // Limpiar el objeto de consulta eliminando propiedades indefinidas
      Object.keys(query).forEach(key => query[key] === undefined && delete query[key]);

      // Llamamos a la función getReviews con los parámetros adecuados
      this.reviewsService.getReviews(id, query, this.actualPage).subscribe(comments => {
        if (comments) {
          this.comments = this.comments.concat(comments);
          this.fillReviewsData(this.comments);
        }
        this.actualPage++;  // Incrementar la página para la próxima carga
        this.loadingMore = false;
      }, error => {
        console.error('Error al cargar más comentarios:', error);
        this.loadingMore = false;
      });
    }
  }

  //método para atualizar los inputs de fecha según la fecha que se seleccione
  updateType(input: 'start' | 'end', type: 'text' | 'date'): void {
    if (input === 'start') {
      if (type === 'date') {
        this.dateStartInput.nativeElement.type = 'date';
      } else if (!this.dateStart) {
        this.dateStartInput.nativeElement.type = 'text';
      }
    } else if (input === 'end') {
      if (type === 'date') {
        this.dateEndInput.nativeElement.type = 'date';
      } else if (!this.dateEnd) {
        this.dateEndInput.nativeElement.type = 'text';
      }
    }
  }


  @HostListener('window:scroll', [])
  // onScroll(): void {
  //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //     this.loadMoreComments();
  //   }
  // }

  //función para el scroll 
  onScroll(): void {
    const lastCommentElement = document.querySelector('.comment-card:last-of-type');
    if (lastCommentElement) {
      const lastCommentPosition = lastCommentElement.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;
      if (lastCommentPosition <= windowHeight) {
        this.loadMoreComments();
      }
    }
  }


  //función para llenar la petición de los datos que queremos
  public fillReviewsData(dataPetiton: any) {
    const reviewsData = [];

    for (let i in dataPetiton) {
      if (dataPetiton[i].localidad) {
        const isAnswered = dataPetiton[i].reply !== undefined && dataPetiton[i].reply !== null;
        const addressLines = dataPetiton[i].localidad?.address?.addressLines ?? [];
        const cleanedComment = this.extractOriginalComment(dataPetiton[i].comment);

        // Procesar las respuestas si existen
        let responses = [];
        if (isAnswered && dataPetiton[i].reply) {
          responses = [{
            updateTime: dataPetiton[i].reply.updateTime,
            comment: this.extractOriginalComment(dataPetiton[i].reply.comment)  // Limpia la respuesta
          }];
        }

        const newReviewsData = {
          id: dataPetiton[i]._id,
          comment: cleanedComment,
          author: dataPetiton[i].author_name,
          date: dataPetiton[i].updated,
          rating: dataPetiton[i].rating,
          addressLines: addressLines,
          isAnswered: isAnswered,
          responses: responses,
          showResponses: false,
          showResponseForm: false,
          urlReviews: dataPetiton[i].localidad.url_reviews,
        };
        reviewsData.push(newReviewsData);
      }
    }
    this.reviews.review = reviewsData;
    this.totalReviews = reviewsData.length;
    
  }

  //método para cambiar el fondo de los comentarios según el rating que tengan 
  getBackgroundColor(rating: number): string {
    if (rating >= 4 && rating <= 5) {
      return '#d2ffdca6'; //verde
    } else if (rating >= 3 && rating < 4) {
      return '#faf2a662'; //amarillo
    } else if (rating >= 1 && rating <= 2) {
      return '#ffc4c43f'; //rojo
    } else {
      return 'white'; // Color predeterminado o cualquier otro color
    }
  }

  //método para extraer los comentarios originales (sin la traducción de google)
  private extractOriginalComment(comment: string): string {
    // Verifica primero si el comentario es null o undefined.
    if (comment === null || comment === undefined) {
      return ''; // Retorna una cadena vacía o algún valor predeterminado que consideres apropiado.
    }

    // Intenta encontrar el patrón que indica la presencia de texto original.
    const originalTextMatch = comment.match(/\(Original\)\s*(.*)/);
    if (originalTextMatch && originalTextMatch.length > 1) {
      // Si encuentra el patrón, devuelve solo la parte original.
      return originalTextMatch[1];
    } else {
      // Si no encuentra el patrón, loguea el caso y devuelve el comentario completo.

      return comment;
    }
  }
  //método para obtener el texto original de la respuesta 
  // private extractOriginalResponse(response: string): string {
  //   if (response === null || response === undefined) {
  //     return ''; // Retorna una cadena vacía si la respuesta es nula o indefinida.
  //   }

  //   const originalTextMatch = response.match(/\(Original\)\s*(.*)/);
  //   if (originalTextMatch && originalTextMatch.length > 1) {
  //     return originalTextMatch[1];
  //   } else {
  //     return response; // Si no se encuentra el texto "(Original)", devuelve la respuesta completa.
  //   }
  // }

  // Suponiendo que esta función se llama después de fillReviewsData
  // public updateAddressInReviewsData(reviewsData: any[], dataPetiton: any) {
  //   for (let i = 0; i < reviewsData.length; i++) {
  //     const addressData = dataPetiton[i].localidad.address; // Acceder a la dirección original
  //     const formattedAddress = { // Formatear la dirección como se desee
  //       addressLines: addressData.addressLines,
  //       administrativeArea: addressData.administrativeArea,
  //       locality: addressData.locality,
  //       postalCode: addressData.postalCode,
  //       regionCode: addressData.regionCode
  //     };
  //     // Actualizar la propiedad adress en el objeto de reviewsData
  //     reviewsData[i].adress = [formattedAddress];
  //   }
  //   // Aquí reviewsData ya tiene la propiedad adress actualizada
  // }


  //función para mostrar las no respondidas
  public showNotAnswered() {
    this.loadingOptions = true; // Mostrar spinner al hacer clic en "Mostrar Todas las Reseñas"
    const id = localStorage.getItem('brandId');
    this.reviewsService.getUnansweredReviews(id).subscribe((comments) => {
      // this.filtredComments = comments; // si usamos éste, debemos usar los datos que nos llegan directamente de la url, es mejor usar el fillReviewsData() que hemos hecho más abajo para tratar los datos como queramos.
      this.fillReviewsData(comments);
      this.loadingOptions = false; // Ocultar spinner después de obtener los comentarios no respondidos
    },
      (error) => {
        console.error('Error al obtener comentarios no respondidos:', error);
        this.loadingOptions = false; // Ocultar spinner en caso de error
      }
    )
  }

  //función para mostrar las respondidas
  public showAnswered() {
    this.loading = true;
    const id = '58da19e2c01e2cfa6b2f2231'; // Asegúrate de usar el ID correcto
    this.reviewsService.getAnsweredReviews(id).subscribe(comments => {
      this.fillReviewsData(comments);
      this.loading = false;
    }, error => {
      console.error('Error al obtener comentarios respondidos:', error);
      this.loading = false;
    });
  }

  // ------------- RATING -------------------
  // Desplegable para el filtro del rating
  public toggleRatingFilter(rating: string, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    // Valida si el rating es un número válido dentro del rango permitido (1-5)
    const validRatings = ['5', '4', '3', '2', '1'];
    const isRatingValid = validRatings.includes(rating);

    if (checkbox.checked) {
      if (isRatingValid && !this.selectedRatings.includes(rating)) {
        this.selectedRatings.push(rating);
      }
    } else {
      if (isRatingValid) {
        this.selectedRatings = this.selectedRatings.filter(r => r !== rating);
      }
    }
  }

  public toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  //método para obtener los comentarios con la opción: todos los ratings
  public toggleAllRatings(target: EventTarget) {
    if (target instanceof HTMLInputElement) {
      this.selectedRatings = [];

      // Si el checkbox "Todos" está marcado, marca la variable isAllSelected como true
      if (target.checked) {
        this.isAllSelected = true;
        this.selectedRatings = ['5', '4', '3', '2', '1'];
      } else {
        this.isAllSelected = false;
      }

      this.updateCheckboxStates();
      this.updateDisplayedRatings();
      this.applyFilters();
    } else {
      console.error('Se esperaba un HTMLInputElement como argumento.');
    }
  }

  
  public updateDisplayedRatings() {
    if (this.isAllSelected) {
      // Muestra "Todos" cuando isAllSelected es true
      document.getElementById('ratingsDisplay').innerHTML = 'Todos';
    } else if (this.selectedRatings.length > 0) {
      document.getElementById('ratingsDisplay').innerHTML = '&#9733; ' + this.selectedRatings.join(', &#9733; ');
    } else {
      document.getElementById('ratingsDisplay').innerHTML = 'Rating';
    }
  }


  // Llama a esta función cuando se selecciona un rating
  public selectRating(rating: string): void {
    this.currentRating = rating; // Actualiza el rating actual con el seleccionado
    // Aquí puedes incluir cualquier otra lógica necesaria cuando se selecciona un rating
  }



  // Actualiza el estado de cada checkbox basado en selectedRatings
  public updateCheckboxStates() {
    ['5', '4', '3', '2', '1'].forEach(rating => {
      const checkbox = document.getElementById(`rating-${rating}`) as HTMLInputElement;
      checkbox.checked = this.selectedRatings.includes(rating);
    });

    // Actualiza también el checkbox "Todos" basado en si todos los ratings están seleccionados
    const allCheckbox = document.getElementById('rating-all') as HTMLInputElement;
    allCheckbox.checked = this.selectedRatings.length === 5;
  }



  // ------------- COMENTARIOS -------------------
  //método para filtrar los comentarios según su estado 
  public loadCommentsBasedOnFilter(event: Event) {
    const target = event.target as HTMLSelectElement;
    const filterValue = target.value;
    this.selectedStatus = filterValue;
  }


  public handleCommentsResponse = (comments) => {
    this.comments = comments; // 'comments' es la propiedad donde almacenamos los comentarios
    // Aquí puedes llamar a cualquier otro método necesario para actualizar tu UI, como fillReviewsData
    this.fillReviewsData(comments);
    this.loading = false; // en falso porque noqueremos que se muestre todo el rato solo cuando cargue
  };

  //---------------MÉTODOS PARA EL SIDEBAR --------------------

  //función para mostrar las respuestas si existen 
  public toggleResponses(review: any): void {
    review.showResponses = !review.showResponses;
  }

  //función para desplegable de añadir respuesta
  public toggleResponseForm(review: any): void {
    review.showResponseForm = !review.showResponseForm;
  }


  //funcion para el submenú desplegable del sidebar
  public toggleSubMenu() {
    const subMenu = document.getElementById('subMenu');
    if (subMenu) {
      subMenu.style.display = subMenu.style.display === 'block' ? 'none' : 'block';
    }
  }
  //---------------FINAL DEL SIDEBAR --------------------

  //---- MÉTODOS PARA CONTROLAR LOS BOTONES DE VER COMENTARIOS O AÑADIR COMENTARIOS (DESPLEGABLE)--------
  //función para desabilitar el botón de "responder" si no hay nada escrito en el text area
  public checkTextarea(id: string): void {
    const textareaContent = this.responseText[id];
  }

  public isTextareaEmpty(id: string): boolean {
    return !this.responseText[id] || this.responseText[id].trim() === '';
  }

  public responseComment(reviewInfo: any, responseText: string) {
    // Verificamos que el comentario tenga una propiedad 'responses'
    if (!reviewInfo.responses) {
      reviewInfo.responses = [];
    }

    // Agregamos la respuesta al array 'responses'
    reviewInfo.responses.push({
      updateTime: new Date().toISOString(),
      comment: responseText
    });

    // Marcamos el comentario como respondido
    reviewInfo.isAnswered = true;

    // Limpiar el textarea después de mostrar el mensaje de error
    this.responseText[reviewInfo.id] = '';

    // Llamada al servicio para enviar la respuesta al servidor
    this.reviewsService.putAnswer(reviewInfo.id, responseText).subscribe(
      (response) => {
        // Manejar la respuesta del servidor si es necesario
  
      },
      (error: HttpErrorResponse) => {
        // Manejar errores si la solicitud al servidor falla
        console.error('Error al enviar respuesta al servidor:', error);

        if (error.status === 401) {
          // Manejar el error específico de autorización
          this.errorMessages[reviewInfo.id] = 'No estás autorizado para realizar esta acción. Por favor, inicia sesión.';
        } else {
          // Manejar otros errores genéricos
          this.errorMessages[reviewInfo.id] = 'Error en el envío de respuesta. Por favor, inténtelo de nuevo.';
        }

        // Establecer el temporizador para limpiar el mensaje de error después de 5 segundos
        this.errorTimeouts[reviewInfo.id] = setTimeout(() => {
          reviewInfo.isAnswered = false; // Marcamos el comentario como no respondido
          this.errorMessages[reviewInfo.id] = ''; // Limpiar el mensaje de error
        }, 5000);
      }
    );
  }


  //para el botón que te lleva al panel
  panel() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/panel']);
    }
  };

  //definimos una función que cargará los datos por defecto si no hay datos y/o mientras carga la página
  public getReviewsDefault() {
    return {
      review: [
        {
          id: '',
          comment: 'sin datos',
          author: 'sin datos',
          date: 0,
          rating: 0,
          urlReviews: 'sin datos',
          isAnswered: false,
          responses: [],
          addressLines: [],
        }
      ]
    };
  }
}
