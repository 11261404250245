import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
@Component({
  selector: 'app-send-email-reset-password',
  templateUrl: './send-email-reset-password.component.html',
  styleUrls: ['./send-email-reset-password.component.scss']
})
export class SendEmailResetPasswordComponent implements OnInit {
  showValidateMessage: boolean;
  showErrorMessage: boolean;
  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  constructor(public router: Router,private authService: AuthService) {
    this.showValidateMessage = false;
    this.showErrorMessage = false;
  }

  ngOnInit(): void {}
  resetPassword() {
    if (this.resetForm.valid) {
      this.authService.postEmail(this.resetForm.value.email).then(res => {
        this.showValidateMessage = true;
        this.showErrorMessage = false;
        setTimeout(() => {
          this.router.navigate(['']);
        }, 5000);
      }).catch(err => {this.showErrorMessage = true; this.showValidateMessage = false;});
    } else {
      // Marca todos los campos como tocados para mostrar errores si el formulario es inválido
      this.resetForm.markAllAsTouched();
    }
  }
  back(){
    this.router.navigate(['']);
}
}
