import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { PanelComponent } from './components/panel/panel.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BusquedasComponent } from './components/busquedas/busquedas.component';
import { VisionadosComponent } from './components/visionados/visionados.component';
import { AccionesComponent } from './components/acciones/acciones.component';
import { ReputacionComponent } from './components/reputacion/reputacion.component';
import { AnalisisComponent } from './components/analisis/analisis.component';
import { NavegadorComponent } from './components/navegador/navegador.component';
import { MapComponent } from './components/map/map.component';
import { HistoricoComponent } from './components/historico/historico.component';
import { LoginTokenComponent } from './components/login-token/login-token.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { CompaniesReviewsComponent } from './components/companies-reviews/companies-reviews.component';
import { ReviewBubbleComponent } from './components/review-bubble/review-bubble.component';
import { LocationsComponent } from './components/locations/locations.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LocationEditComponent } from './components/location-edit/location-edit.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SendEmailResetPasswordComponent } from './components/send-email-reset-password/send-email-reset-password.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { DataTablesModule } from "angular-datatables";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SidebarComponent } from './components/sidebar/sidebar.component';

// import DataTables from "datatables.net";


// import { MatPaginatorModule } from '@angular/material/paginator';





@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PanelComponent,
    BusquedasComponent,
    VisionadosComponent,
    AccionesComponent,
    ReputacionComponent,
    AnalisisComponent,
    NavegadorComponent,
    MapComponent,
    HistoricoComponent,
    LoginTokenComponent,
    CompaniesReviewsComponent,
    ReviewBubbleComponent,
    LocationsComponent,
    LocationEditComponent,
    ForgotPasswordComponent,
    SendEmailResetPasswordComponent,
    // MatPaginatorModule,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxUiLoaderModule,
    DataTablesModule,
    InfiniteScrollModule,
    SidebarComponent,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http), //linea corregida que sinó daba error
        deps: [HttpClient]
      }
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
