import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { topConnect } from '../../environments/environment'
import { query } from '@angular/animations';
/* eslint-disable */

@Injectable({
  providedIn: 'root'
})
export class CompaniesReviewsService {

  constructor(private http: HttpClient) { }


  public getReviews(id: string, query: any, page: number): Observable<any[]> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });

    let url = `${topConnect.apiUrl}dashboard/reviews/?limit=10&marca=${id}&page=${page}`;  // Agregado &page=${page}

    if (query.reply_exists !== undefined) {
      url += `&reply_exists=${query.reply_exists}`;
    }

    if (query.rating !== undefined) {
      url += `&rating=${query.rating}`;
    }

    // Agregar filtro de fecha
    if (query.dateStart !== undefined && query.dateEnd !== undefined) {
      url += `&dateStart=${query.dateStart}&dateEnd=${query.dateEnd}`;
    }
    // if (query.date !== undefined) {
    //   url += `&date=${query.date}`;
    // }

    return this.http.get<any[]>(url, { headers });
  }


  // Obtener todos los comentarios
  public getCommentsPage(id: string, page: number, limit: number): Observable<any[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });

    // const offset = (page - 1) * limit;
    const url = `${topConnect.apiUrl}dashboard/reviews/?limit=${limit}&marca=${id}&page=${page}`;

    return this.http.get<any[]>(url, { headers });

  }

  public getTotalCommentsCount(id: string, query: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });

    // Comienza con la URL básica que incluye el parámetro para obtener solo el conteo
    let url = `${topConnect.apiUrl}dashboard/reviews/?count=1&marca=${id}`;

    // Agrega condiciones de filtro según el objeto query que recibe la función
    if (query.reply_exists !== undefined) {
      url += `&reply_exists=${query.reply_exists}`;
    }

    if (query.rating !== undefined) {
      url += `&rating=${query.rating}`;
    }

    // Añade los filtros de fecha si están disponibles
    if (query.dateStart !== undefined) {
      url += `&dateStart=${query.dateStart}`;
    }

    if (query.dateEnd !== undefined) {
      url += `&dateEnd=${query.dateEnd}`;
    }
    // Realiza la solicitud HTTP GET con los headers adecuados
    return this.http.get<any>(url, { headers });
  }

  



  // Obtener solo comentarios no respondidos
  public getUnansweredReviews(id: string): Observable<any[]> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get<any[]>(`${topConnect.apiUrl}dashboard/reviews/?limit=10&reply_exists=false&marca=${id}`, { headers });
  }

  // Obtener solo comentarios respondidos
  public getAnsweredReviews(id: string): Observable<any[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get<any[]>(`${topConnect.apiUrl}?reply_exists=true&marca=${id}`, { headers });
  }

  // Función para enviar respuestas
  public putAnswer(reviewId: string, textResponse: string): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    // const url = `${this.putApi}/review/${reviewId}/reply`;
    const body = { comment: textResponse };

    return this.http.put(`${topConnect.apiUrl}review/${reviewId}/reply`, body, { headers }).pipe(
      catchError((error) => {
        // Aquí puedes agregar lógica adicional si es necesario
        console.error('Error en el envío de respuesta:', error);
        throw error; // Propagar el error para que el componente también pueda manejarlo
      })
    );
  }
}
