<body>
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center m-auto">
        <form class="box" method="post">
          <img class="logImg" src="assets/img/logo.png" alt="logo">
          <input type="email" [(ngModel)]="email" name="email" placeholder="Email" required="required"/>
          <input type="password" [(ngModel)]="password" name="password" placeholder="Contraseña" required="required"/>
          <div *ngIf="showErrorMessage">
            <p class="error-login">Email, contraseña incorrectos o permisos insuficientes</p>
          </div>
          <button type="submit" (click)="onSubmitLogin()">Iniciar Sesión</button>
          <a class="forgot-password" href="/send-email-reset-password"
          role="button">Recuperar contraseña</a>
          
        </form>
      </div>
    </div>
  </div>
</body>
