import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {RouterModule} from '@angular/router';


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  constructor(public router: Router,) {

  }


  reviews() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/companies-reviews']);
    }
  };

  locations() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/locations']);
    }
  };


  panel() {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded", "off")
    } else {
      this.router.navigate(['/panel']);
    }
  };
  logout(){
    localStorage.removeItem('access_token');
    localStorage.setItem('pageReloaded','off');
    this.router.navigate(['']);
  }

}
