<body>
    <div class="container">        
    <div class="row">
<div class="reset-password-container">
    <h2 class="title">Restablecer Contraseña</h2>
    <form [formGroup]="resetForm" class="password-form" (ngSubmit)="resetPassword()">
      <div>
        <label class="title" for="email">Correo Electrónico:</label>
        <input type="email" id="email" formControlName="email">
        <div *ngIf="resetForm.get('email').invalid && resetForm.get('email').touched" class="error-message">
          <div *ngIf="resetForm.get('email').errors?.required">El correo electrónico es requerido.</div>
          <div *ngIf="resetForm.get('email').errors?.email">El correo electrónico no es válido.</div>
        </div>
      </div>
      <button type="submit" [disabled]="resetForm.invalid">Enviar Correo</button>
      <button class="back" type="submit" (click)="back()">Volver atras</button>
    </form>
    <div *ngIf="showValidateMessage">
        <p class="validate-send">Mensaje enviado</p>
    </div>
    <div *ngIf="showErrorMessage">
        <p class="error-login">No existe una cuenta con este email, porfavor contacte con un administrador</p>
    </div>
    </div>
</div>
</div>
</body>