<app-sidebar></app-sidebar>
<div class="container-fluid">

        <!-- TABLE CONTENT -->
        <div class="col offset-2 table-responsive" id="main">
            <!-- <div *ngIf="loading" class="custom-spinner"></div> -->
            <table id="locations_table" class="table table-striped table-hover caption-top row-border hover">
                <caption>Lista de ubicaciones</caption>
                <thead class="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Dirección</th>
                        <th>Localidad</th>
                        <th>Provincia</th>
                        <th>Reviews</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let location of LocationsInfo.locations" (click)="onLocationClick(location)">
                        <td>{{ location.codigoTienda }}</td>
                        <td><a [routerLink]="'/edit-location/' + location.id">{{ location.nombre }}</a></td>
                        <td>{{ location.direccion }}</td>
                        <td>{{ location.localidad }}</td>
                        <td>{{ location.provincia }}</td>
                        <td>{{ location.totalReviews }}</td>
                        <td>{{ location.rating }}</td>
                    </tr>
                </tbody>
            </table>
            
            <!-- <div class="fixed-bottom bg-white offset-2 d-flex flex-center justify-content-lg-center pt-4">
                <pagination-controls (pageChange)="currentPage = $event" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
            </div> -->
            
        </div>
    
</div>
