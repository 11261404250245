import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment'
/* eslint-disable */

@Injectable({
  providedIn: 'root'
})
export class LocationsService {


  constructor(private http: HttpClient) { }

  public getInfoLocations(id: string ){
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get(`${topConnect.apiUrl}dashboard/locations/`, {headers});
  }

  //.*******************************************************************************

  // Nuevo método para obtener ubicación por código de tienda
  public getLocationByCodigoTienda(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    // Cambiado a un método GET y corregido la ruta.
    return this.http.get(`${topConnect.apiUrl}localidad/${id}`, { headers });
  }
  
  // Nuevo método para actualizar los datos de la ubicación
  public updateLocation(location: any): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    // Cambiado a un método PATCH y corregido la ruta.
    return this.http.patch(`${topConnect.apiUrl}localidades/attributes/${location.id}`, location, { headers });
    
  }

  public getLocationById(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'content-Type': 'application/json',
      'Authorization': localStorage.getItem('access_token')
    });
    return this.http.get(`${topConnect.apiUrl}localidades/${id}`, { headers });
  }
}